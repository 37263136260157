/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Swiper (carrousel) */
@import '~swiper/swiper-bundle.css';

:root {
  --ion-color-confirmation: var(--app-color-confirm);
  --ion-color-confirmation-rgb: 153,153,153;
  --ion-color-confirmation-contrast: #000000;
  --ion-color-confirmation-contrast-rgb: 0,0,0;
  --ion-color-confirmation-shade: #878787;
  --ion-color-confirmation-tint: #a3a3a3;

  --ion-color-gray: #999;
  --ion-color-gray-rgb: 153,153,153;
  --ion-color-gray-contrast: #000000;
  --ion-color-gray-contrast-rgb: 0,0,0;
  --ion-color-gray-shade: #878787;
  --ion-color-gray-tint: #a3a3a3;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255,255,255;

  --ion-text-color: #999;
  --ion-text-color-rgb: 153,153,153;

  --ion-color-step-50: #fafafa;
  --ion-color-step-100: #f5f5f5;
  --ion-color-step-150: #f0f0f0;
  --ion-color-step-200: #ebebeb;
  --ion-color-step-250: #e6e6e6;
  --ion-color-step-300: #e0e0e0;
  --ion-color-step-350: #dbdbdb;
  --ion-color-step-400: #d6d6d6;
  --ion-color-step-450: #d1d1d1;
  --ion-color-step-500: #cccccc;
  --ion-color-step-550: #c7c7c7;
  --ion-color-step-600: #c2c2c2;
  --ion-color-step-650: #bdbdbd;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #b3b3b3;
  --ion-color-step-800: #adadad;
  --ion-color-step-850: #a8a8a8;
  --ion-color-step-900: #a3a3a3;
  --ion-color-step-950: #9e9e9e;
}

ion-progress-bar::part(track) {
  background: #ededed; // ion-bar TRACK background color
}

.ion-color-confirmation {
  --ion-color-base: var(--ion-color-confirmation);
}

.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

ion-datetime-button::part(native) {
  width: 100%;
  background: #ffffff;
  text-align: left;
  padding-inline: 0px;
}

.sc-ion-input-ios-h {
  --padding-start: 10px;
}

body {
  font-size: 16px;
  font-family: "Open Sans", Arial, sans-serif;
  color: #777;
  box-sizing: border-box;
  line-height: 1.42857;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

/* hide recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}

.searchbar-input-funds {
  input{
    font-size: 0.9em !important;
  }
}

ion-modal {
  align-items: center;
  justify-content: center;
}

ion-modal.ios {
  padding-top: var(--ion-safe-area-top);
  padding-bottom: var(--ion-safe-area-bottom);
}

ion-modal.modal-size-small {
  --max-width: 27em;
  --max-height: 18em;
}

ion-modal.modal-size-medium {
  --max-width: 35em;
  --max-height: 27em;
}

ion-popover.popover-full-width {
  --width: 70%;
}

ion-modal.modal-size-large {
  --min-height: 70%;
  --min-width: 70%;
  --max-height: 95%;
  --max-width: 95%;
}

ion-modal.modal-size-signaturit {
  --min-height: 87%;
  --max-height: 87%;
  --min-width: 85%;
  --max-width: 95%;
  --border-radius: 6px;
}

.alert-title {
  text-align: center;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: inherit !important; // TODO: text color for autofilled, need extends from default color in normal mode
  transition: background-color 5000s ease-in-out 0s;
}

.sc-ion-select-popover-ios,
.sc-ion-select-popover-md{
  white-space: normal !important;
}

.ion-item-accordion{
  --inner-padding-end: 0px;
  --padding-start:0px
}

ion-accordion-group{
  ion-accordion {
    .ion-accordion-toggle-icon {
      font-size: 12px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.ion-range::part(knob) {
  left: calc(50% - 20px / 2);
  top: calc(50% - 20px / 2);
  width: 20px;
  height: 20px;
  background: red;
}

.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
  font-size: 0.8rem;
}

.searchbar-input.sc-ion-searchbar-md {
  font-size: 0.8rem;
  padding-right: 0px;
  padding-inline-end: 0px;
}

.preserve-3d {
  transform-style: preserve-3d;
}

.backface-hidden {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.rotate-x-180 {
  transform: rotateX(180deg);
}

.safearea-ios-top {
  padding-top: var(--ion-safe-area-top);
}
.safearea-ios-bottom {
  padding-bottom: var(--ion-safe-area-bottom);
}

ion-tab-button {
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0.5em;
  margin-left: 0.1em;
  margin-right: 0.1em;
}

ion-tab-bar {
  padding-bottom: 0px;
}

ion-tab-button::part(native):hover {
  color: unset;
}

.no-padding {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

.no-results {
  height: 100%;
}

.webkit-text-white {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    -webkit-text-fill-color: white !important;
  }
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

@keyframes text-animation {
  20%, 80% {
    color: rgb(229, 22, 22);
  }
}

.alert-text-animation {
  animation:text-animation 1s linear 1;
}

@keyframes rowSelectNextAction {
   0%, 100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0px);
  }
}

.row-select-next-action {
  animation: rowSelectNextAction 1s linear 4;
}

@keyframes colorInOut {
  30%, 100% {
    background-color: transparent ;
  }
  0% {
    background-color: color-mix(in srgb, var(--button-color-primary), transparent 80%) ;
  }
}

.color-in-out {
  animation: colorInOut 3s linear 1;
}

.color-in-out-infinite {
  animation: colorInOut 3s linear 4;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.border-6 {
  border-width: 6px;
}

.border-7 {
  border-width: 7px;
}

