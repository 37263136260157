// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --app-color-primary: #007363;
  --app-color-primary-hover: #006152;
  --app-color-primary-light: #00d9bb;
  --app-color-primary-light-hover: #08c1a7;
  --app-color-primary-stroke:#ffffff;
  --app-color-primary-stroke-historic:#007363;
  --app-color-border-primary: #007363;
  --app-color-border-secondary: #007363;
  --app-color-secondary-hover: unset;
  --app-color-background: #007363;
  --app-color-background-2: #007363b3;
  --app-color-confirm: #69bd7b;
  --app-color-confirm-hover: #4db062;

  --rounded-default: 9999px;

  --button-color-primary: #24b68f;
  --button-color-success: #69bd7b;
  --button-color-primary-hover: #007363;
  --button-color-secondary-hover: #007363;
  --button-color-tertiary-hover: #007363;
  --button-color-success-hover: #4db062;
  --button-color-primary-border: #24b68f;
  --button-color-secondary-border: #007363;
  --button-color-primary-text: #007363;
  --button-color-primary-text-hover: #ffffff;
  --button-color-secondary-text: #007363;
  --button-color-secondary-text-hover: #007363;
  --button-color-tertiary-text: #007363;
  --button-color-tertiary-text-hover: #ffffff;
}

.auth-background {
  background-image: linear-gradient(var(--app-color-background), var(--app-color-background-2), var(--app-color-background)),
  url('../assets/auth/login.jpg');
}
